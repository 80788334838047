<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row mt-4 p-3 align-items-center">
                <div class="col-md-6 text-md-left text-center">
                  <h2 class="page-title mb-md-0">{{ $t("label.Support Settings") }}</h2>
                </div>
                <div class="col-md-6 text-right d-md-flex justify-content-end mb-3"></div>
              </div>
              <div class="card card-body form-style content-card">
                <Form class="form-style" @submit="save()">
                  <div class="row mb-md-4 mb-3">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="email">{{ $t("label.Email") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          v-model="detail.email"
                          class="form-control filled"
                          rules="required:email"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="email" class="validation-msg" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group country-code-ui">
                        <label for="phone">{{ $t("label.Phone") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="phone"
                          name="phone"
                          v-model="detail.phone_number"
                          class="form-control filled"
                          rules="required:phone number|phone"
                          :validateOnInput="true"
                          ><PhoneCode
                            id="mobile"
                            placeholder="Mobile Number"
                            v-model:countryCode="detail.country_code"
                            v-model="detail.phone_number"
                            v-model:dialCode="detail.dial_code"
                            v-bind="field"
                          />
                        </Field>
                      </div>
                      <ErrorMessage name="phone" class="validation-msg" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="">{{ $t("label.Company Name (English)") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="company_name_en-order"
                          name="company_name_en-order"
                          v-model="detail.company_name_en"
                          class="form-control filled"
                          rules="required:Company Name (English)"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="company_name_en-order" class="validation-msg" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="">{{ $t("label.Company Name (Arabic)") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="company_name_ar-order"
                          name="company_name_ar-order"
                          v-model="detail.company_name_ar"
                          class="form-control filled"
                          rules="required:Company Name (Arabic)"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="company_name_ar-order" class="validation-msg" />
                    </div>

                    <div class="col-12 text-center mt-4">
                      <button id="save-button" class="btn btn-brand-1 mr-2">
                        {{ $t("label.Save") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-brand-1 cancel"
                        v-on:click="$router.go(-1)"
                      >
                        {{ $t("label.Cancel") }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "SupportPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "support-settings",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },

    save() {
      this.$api
        .putAPI({
          _action: "support-settings",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

