<template>
  <main role="main">
    <div class="d-lg-flex" id="main-wraper">
      <div class="page-wrapper" style="width: 140%">
        <div class="page-content">
          <div class="row mb-4 justify-content-center">
            <div class="col-lg-12">
              <div class="row mt-4 p-3 align-items-center">
                <div class="col-md-6 text-md-left text-center">
                  <h2 class="page-title mb-md-0">{{ $t("label.Setting") }}</h2>
                </div>
                <div class="col-md-6 text-right d-md-flex justify-content-end mb-3">
                  <button
                    type="button"
                    class="btn btn-brand-1 btn-sm"
                    v-on:click="showModal()"
                  >
                    {{ $t("label.Update Commisssion for All Suppliers") }}
                  </button>
                </div>
              </div>
              <div class="card card-body form-style content-card">
                <Form class="form-style" @submit="save()">
                  <div class="row mb-md-4 mb-3">
                    <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label class="">{{
                          $t("label.Product Delivery Charges (SAR)")
                        }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="shipping_charges"
                          name="shipping_charges"
                          v-model="detail.shipping_charges"
                          class="form-control filled"
                          rules="required:shipping_charges"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="shipping_charges" class="validation-msg" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="">{{
                          $t("label.Minimum Order Free Shipping")
                        }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="min-order"
                          name="min-order"
                          v-model="detail.min_order_value_for_free_shipping"
                          class="form-control filled"
                          rules="required:minimun order free sheeping"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="min-order" class="validation-msg" />
                    </div> -->

                    <div class="col-md-8">
                      <label for="productfees">{{
                        $t("label.Admin Product Fees")
                      }}</label>
                      <span class="text-danger">*</span>
                      <div class="form-group">
                        <Field
                          type="text"
                          id="productfees"
                          name="productfees"
                          class="form-control"
                          rules="required:product fees|numeric|max_value:100|min_value:0"
                          v-model="detail.product_commission"
                          :validateOnInput="true"
                          v-if="detail.product_commission_type == 'PERCENT'"
                        />
                        <Field
                          type="text"
                          id="productfees"
                          name="productfees"
                          class="form-control"
                          rules="required:product fees|numeric"
                          v-model="detail.product_commission"
                          :validateOnInput="true"
                          v-else
                        />
                      </div>
                      <ErrorMessage name="productfees" class="validation-msg" />
                    </div>

                    <div class="col-lg-4" style="margin-top: 5px">
                      <label for="role">
                        <!-- {{
                                  $t("label.Product Commission Type")
                                }} -->
                      </label>
                      <div class="form-group">
                        <select
                          name="product_commission_type"
                          rules="required:product commission type"
                          class="form-control py-2"
                          id=""
                          v-model="detail.product_commission_type"
                        >
                          <!-- <option value="">
                                      {{ $t("label.Select Product Commission Type") }}
                                    </option> -->
                          <option value="FIXED">{{ $t("label.Fixed") }}</option>
                          <option value="PERCENT">
                            {{ $t("label.Percentage") }}
                          </option>
                        </select>
                      </div>
                      <ErrorMessage
                        name="product_commission_type"
                        class="validation-msg mt-3"
                      />
                    </div>
                    <div class="col-md-8">
                      <label for="servicefees">{{
                        $t("label.Admin Service Fees")
                      }}</label>
                      <span class="text-danger">*</span>
                      <div class="form-group">
                        <Field
                          type="text"
                          id="servicefees"
                          name="servicefees"
                          class="form-control"
                          rules="required:service fees|numeric|max_value:100|min_value:0"
                          v-model="detail.service_commission"
                          :validateOnInput="true"
                          v-if="detail.service_commission_type == 'PERCENT'"
                        />
                        <Field
                          type="text"
                          id="servicefees"
                          name="servicefees"
                          class="form-control"
                          rules="required:service fees|numeric"
                          v-model="detail.service_commission"
                          :validateOnInput="true"
                          v-else
                        />
                      </div>
                      <ErrorMessage name="servicefees" class="validation-msg" />
                    </div>

                    <div class="col-lg-4" style="margin-top: 5px">
                      <label for="role">
                        <!-- {{
                                  $t("label.Select Service Commission Type")
                                }} -->
                      </label>
                      <!-- <span class="text-danger">*</span> -->
                      <div class="form-group">
                        <select
                          name="service_commission_type"
                          rules="required:service commission type"
                          class="form-control py-2"
                          id=""
                          v-model="detail.service_commission_type"
                        >
                          <!-- <option value="">
                                      {{ $t("label.Select Service Commission Type") }}
                                    </option> -->
                          <option value="FIXED">{{ $t("label.Fixed") }}</option>
                          <option value="PERCENT">
                            {{ $t("label.Percentage") }}
                          </option>
                        </select>
                      </div>
                      <ErrorMessage
                        name="service_commission_type"
                        class="validation-msg mt-3"
                      />
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="">{{ $t("label.VAT") }}</label>
                        <span class="text-danger">*</span>
                        <Field
                          type="text"
                          id="vat-order"
                          name="vat-order"
                          v-model="detail.vat"
                          class="form-control filled"
                          rules="required:VAT"
                          :validateOnInput="true"
                        />
                      </div>
                      <ErrorMessage name="vat-order" class="validation-msg" />
                    </div>
                   

                    <div class="col-12 text-center mt-4">
                      <button id="save-button" class="btn btn-brand-1 mr-2">
                        {{ $t("label.Save") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-brand-1 cancel"
                        v-on:click="$router.go(-1)"
                      >
                        {{ $t("label.Cancel") }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="spx-modal h-100 justify-content-center">
        <div class="spx-modal-body mx-auto">
          <div class="modal-header pt-0">
            <button type="button" class="close" v-on:click="$refs.textModal.closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title">{{ $t("label.Confirmation") }}</h5>
          </div>
          <Form @submit="saveCommision">
            <div class="modal-body">
              <h6>
                {{
                  $t("label.Are you sure, want to update commisssion for all suppliers")
                }}
              </h6>
            </div>
            <div class="modal-footer pb-0">
              <button
                class="fill-btn btn-success"
                id="saveq-button"
                style="width: 18%; border-radius: 5px"
              >
                {{ $t("label.Save") }}
              </button>
              <button
                type="button"
                class="fil-btn btn-danger"
                id="cancel-button"
                style="width: 18%; border-radius: 5px"
                v-on:click="$refs.textModal.closeModal()"
              >
                {{ $t("label.Cancel") }}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </TextModal>
  </main>
</template>

<script>
import TextModal from "../../components/TextModal.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "SettingPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "master-settings",
        })
        .then((res) => {
          this.detail = res.info;
        })
        .catch(() => {});
    },
    showModal() {
      this.$refs.textModal.showModal();
    },
    saveCommision() {
      this.$api
        .postAPI({
          _action: "commission/suppliers",
          _body: {},
          _buttonId: "saveq-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.$refs.textModal.closeModal();
          }
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .putAPI({
          _action: "master-settings",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>

